<template>
  <b-modal
    v-model="state"
    size="lg"
    content-class="rounded-0"
    footer-class="border-top-0"
    centered
    no-close-on-esc
    no-close-on-backdrop
    @cancel="onCancel"
  >
    <template v-slot:modal-header>
      <h5 class="mb-0">
        <span v-html="$t(titleText, titleParams)"></span>
      </h5>
    </template>
    <template v-slot:default>
      <user-table :users="formatedUsers" :fields="fields" />
    </template>
    <template v-slot:modal-footer="{ cancel }">
      <b-button
        variant="link"
        class="text-dark text-decoration-none"
        @click="cancel"
        >{{ $t(cancelBtnLabel) }}</b-button
      >
    </template>
  </b-modal>
</template>

<script>
import UserTable from "./../User/UserTable";
import { authMixin, userMixin } from "./../../mixins";

export default {
  components: {
    UserTable
  },
  mixins: [authMixin, userMixin],
  data() {
    return {
      formatedUsers: [],
      fields: [
        { key: "fullName", label: "nameAndSurname", sort: true },
        { key: "email", label: "email", sort: true },
        { key: "status", label: "status", sort: false },
        { key: "createdAt", label: "createdat", sort: true }
      ]
    };
  },
  methods: {
    onCancel() {
      this.$store.dispatch("blockList/cancelAction");
    },
    getUsers() {
      var users = [];
      return new Promise((resolve, reject) => {
        this.messageParams.forEach(async (report, index, array) => {
          await this.$store.dispatch("consumer/retrieveConsumerUser", report);
          const user = this.$store.getters["consumer/getCurrentConsumerUser"];
          users.push(user);
          if (index === array.length - 1) resolve(users);
        });
      });
    }
  },
  watch: {
    async state() {
      const users = await this.getUsers();
      this.formatedUsers = users.map(item => {
        return {
          id: item._id,
          photo: this.$_getUserProfilePhoto(item.profile),
          fullName: this.$_getUserFullName(item.profile),
          email: item.email,
          status: item.status,
          createdAt: item.createdAt
        };
      });
    }
  },
  computed: {
    state: {
      get() {
        return this.$store.getters["blockList/isActive"];
      },
      set(active) {
        this.$store.commit("blockList/SET_ACTIVE", active);
      }
    },
    titleText() {
      return this.$store.getters["blockList/getTitleText"];
    },
    titleParams() {
      return this.$store.getters["blockList/getTitleParams"];
    },
    messageParams() {
      return this.$store.getters["blockList/getMessageParams"];
    },
    cancelBtnLabel() {
      return this.$store.getters["blockList/getCancelBtnLabel"];
    }
  }
};
</script>

<style scoped></style>
