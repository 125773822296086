<template>
  <b-row class="no-gutters ml-n3">
    <b-col class="col-12 col-md-6 col-lg-4 pl-3">
      <search-input
        v-model="searchInput"
        label="search"
        :placeholder="$t('page.consumerUser.searchPlaceholder')"
        @search="search"
      ></search-input>
    </b-col>
    <b-col class="col-12 col-md-6 col-lg-4 pl-3">
      <form-select v-model="filterUserStatus" label="status">
        <b-form-select-option :value="null">
          {{ $t("all") }}
        </b-form-select-option>
        <b-form-select-option
          v-for="(status, index) in consumerStatusOptions"
          :key="index"
          :value="status"
        >
          {{ $t(status) }}
        </b-form-select-option>
      </form-select>
    </b-col>
    <b-col class="align-self-center pl-4 pt-3">
      <b-form-checkbox
        id="block_status"
        v-model="blockedFilter"
        name="block_status"
      >
        {{ $t("button.showBlocked") }}
      </b-form-checkbox>
    </b-col>
  </b-row>
</template>

<script>
import SearchInput from "./../../../../console-components/BaseSearchInput";
import FormSelect from "./../../../../console-components/BaseFormSelect";
export default {
  components: {
    SearchInput,
    FormSelect
  },
  data() {
    return {
      searchInput: "",
      consumerStatusOptions: ["active", "invited", "notverified"]
    };
  },
  mounted() {
    this.searchInput = this.filterSearch;
  },
  computed: {
    filterSearch: {
      get() {
        return this.$store.getters["consumer/getFilterSearch"];
      },
      set(value) {
        this.$store.commit("consumer/SET_FILTER_SEARCH", value);
      }
    },
    filterUserStatus: {
      get() {
        return this.$store.getters["consumer/getFilterUserStatus"];
      },
      set(value) {
        this.$store.commit("consumer/SET_FILTER_USER_STATUS", value);
        this.retrieveConsumerUsers();
      }
    },
    blockedFilter: {
      get() {
        return this.$store.getters["consumer/getFilterBlocked"];
      },
      set(value) {
        this.$store.commit("consumer/SET_FILTER_BLOCKED", value);
        this.retrieveConsumerUsers();
      }
    }
  },
  methods: {
    search() {
      this.filterSearch = this.searchInput;
      this.retrieveConsumerUsers();
    },
    async retrieveConsumerUsers() {
      try {
        this.$store.commit("loader/SET_LOADER", {});
        await this.$store.dispatch("consumer/retrieveConsumerUsers");
      } catch (error) {
        console.log(error);
      } finally {
        this.$store.commit("loader/RESET");
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
